//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapActions} from 'vuex'
import {adminCodes} from "@/enums/adminSettings";

export default {
  name: 'Header',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainMenuItems: [],
      isSticky: false,
      miniSearchOpen: false,
      mobileMenuOpen: false,
      keyword: '',
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
  },
  async mounted() {
    await this.getMenu()
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions('shoppingCart', ['setCartSideOpen']),
    getItems(items) {
      const vueObj = {}
      Object.assign(vueObj, items)
      return vueObj
    },
    async getMenu() {
      const header_data = (await this.$axios.get(`/contents/header-menu`)).data
      this.mainMenuItems = this.getItems(
        header_data.details.sections[0].menu_items
      )
      return this.mainMenuItems
    },
    onScroll() {
      const scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      )
      const windowHeight = document.documentElement.clientHeight

      if (Math.abs(scrollHeight - windowHeight) < 142) {
        window.removeEventListener('scroll', this.onScroll)
      } else {
        this.isSticky = Math.round(window.pageYOffset) > 0
      }
    },
    openMiniSearch() {
      this.miniSearchOpen = !this.miniSearchOpen
    },
    openShoppingCartSide() {
      this.setCartSideOpen(true)
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false
    },
    openMobileMenu() {
      this.mobileMenuOpen = true
    },
    search() {
      if (this.keyword != null && this.keyword !== '') {
        this.$router.push({
          path: 'product-category',
          query: {keyword: this.keyword},
        })
      }
      this.mobileMenuOpen = false
    },
  },
}
